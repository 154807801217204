import React from "react"
import {graphql, Link} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class Index extends React.Component {
    render() {
        const {data} = this.props
        const siteTitle = data.site.siteMetadata.title

        return (
            <Layout location={this.props.location} title={siteTitle}>
                <SEO title="Jerrie Pelser's website and blog"/>
                <header className="px-4 lg:px-0 pt-10 md:pt-24 pb-10 md:pb-24">
                    <h1 className="text-3xl">
                        Hi, I'm Jerrie{" "}
                        <span role="img" aria-label="hand waving">
              👋
            </span>
                    </h1>
                    <h2 className="mt-8 text-xl">
                        I like to code{" "}
                        <span role="img" aria-label="male computer programmer">
              👨‍💻
            </span>
                        , I like to write{" "}
                        <span role="img" aria-label="hand writing">
              ✍
            </span>
                        , I like to bake{" "}
                        <span role="img" aria-label="bread">
              🍞
            </span>
                        , and I like to travel{" "}
                        <span role="img" aria-label="airplane">
              ✈
            </span>
                    </h2>
                    <p className="mt-8 prose">
                        If you're a fellow software developer, feel free to <Link to={`/blog`}> check out my
                        blog</Link> where I write about various programming and technology topics.
                    </p>
                    <p className="mt-4 prose">
                        I work as a freelance software developer, so if you need an extra hand on your project,
                        please <Link to={`/hire`}> get in contact
                    </Link>.
                    </p>
                </header>
            </Layout>
        )
    }
}

export default Index

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`
